<template>

  <div class="tasks">

    <header-tasks-container name="Задачи"></header-tasks-container>

    <div id="tasks_main_wrapper">

      <ul id="tasks_main">

          <li v-for="item in taskItems" :key="item">
            <router-link :to="{ name: 'TasksID', params: { id: item.itemID}}">
              <transition name="d_u_move" appear>
              <div class="tasks_main_item">

                <div class="tasks_item_ID">
                  {{ item.itemID }}
                </div>
                <div class="tasks_item_name">
                  {{ item.title }}
                </div>

              </div>
              </transition>
            </router-link>
          </li>
      </ul>
    </div>
  </div>
</template>

<script>

import headerTasksContainer from "@/components/headerTasksContainer.vue";

export default {
  name: "Tasks",
  components: { headerTasksContainer },
  data() {
    return {
    }
  },
  computed: {
    taskItems() {
      return this.$store.getters.getTaskItems
    }
  },
  mounted() {
  },
}
</script>

<style scoped>

.tasks {
  width:                  100%;
  max-width:              100%;
  background-color:       #fdf5e6;
  font-family: sans-serif, Helvetica;
  margin-top:             var(--header-height);
}

#tasks_main_wrapper {
  display:                grid;
  grid-template-columns:  1fr;
  max-width:              var(--width-wrapper-small);
  min-height:             100vh;
  place-content:          center;
  place-items:            center;
  padding-left:           .5rem;
  padding-right:          .5rem;
  margin:                 1rem auto 1rem auto;
}

@media (min-width: 60rem) {
  #tasks_main_wrapper {
    max-width:              var(--width-wrapper-middle);
  }
}
@media (min-width: 75rem) {
  #tasks_main_wrapper {
    max-width:              var(--width-wrapper-large);
  }
}

#tasks_main {
  display: 			    grid;
  grid-template-columns:  1fr;
  grid-gap:               .2rem;
  min-width: 100%;
  max-width:        100%;
}

.tasks_main_item {
  display:                grid;
  grid-template-columns:  3.125rem 1fr;
  grid-template-rows:     auto;
  min-height:             2.5rem;
  opacity:                1;
  transition:             opacity 500ms;
  grid-column-gap:        9px;
  color:                  #666;
}

.tasks_main_item:hover {
  color:                  #999;
}

@media (min-width: 60rem) {
  .tasks_main_item {
    grid-template-columns:  4rem 1fr;
    min-height:             3.125rem;
    grid-column-gap:        20px;
  }
}

.tasks_item_ID {
  display:                grid;
  max-height:             2.5rem;
  background:             none;
  margin:                 auto;
  grid-template-columns:  1fr;
  grid-template-rows:     auto;
  min-height:             2.5rem;
  height:                 100%;
  width:                  100%;
  align-items:            center;
  justify-items:          center;
  font-size:              var(--font-size-small);
  font-weight:            300;
  transition:             all 200ms;
}

@media (min-width: 60rem) {
  .tasks_item_ID {
    font-size:              var(--font-size-middle);
    display:                grid;
    max-height:             3.125rem;
    background:             none;
    margin:                 auto;
    grid-template-columns:  1fr;
    grid-template-rows:     auto;
    min-height:             3.125rem;
    height:                 100%;
    width:                  100%;
    align-items:            center;
    justify-items:          center;
    font-weight:            300;
    transition:             all 200ms;
  }
}

.tasks_item_name {
  display:                grid;
  grid-template-columns:  1fr;
  grid-template-rows:     auto;
  min-height:             2.5rem;
  height:                 100%;
  width:                  100%;
  align-items:            center;
  justify-items:          left;
  font-size:              var(--font-size-middle);
  font-weight:            300;
  transition:             all 200ms;
}

@media (min-width: 60rem) {
  .tasks_item_name {
    font-size:              var(--font-size);
    min-height:             3.125rem;
  }
}

.d_u_move-enter-active {
  transition: all 1s ease-out;
}

.d_u_move-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.d_u_move-enter-from,
.d_u_move-leave-to {
  transform: translateY(150px);
  opacity: 0;
}

</style>